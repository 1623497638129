import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Painel",
  },
  {
    id: "timeline",
    title: "Linha do Tempo",
    type: "item",
    icon: <Icon.GitCommit size={15} />,
    navLink: "/timeline",
    permissions: ["admin", "editor"],
  },
  {
    type: "groupHeader",
    groupTitle: "Cadastro",
  },
  {
    id: "place",
    title: "Local",
    type: "item",
    icon: <Icon.MapPin size={15} />,
    navLink: "/places",
    permissions: ["admin", "editor"],
  },
  {
    id: "responsibilities",
    title: "Cargos",
    type: "item",
    icon: <Icon.Award size={15} />,
    navLink: "/responsibilities",
    permissions: ["admin", "editor"],
  },
  {
    id: "shift",
    title: "Turno",
    type: "item",
    icon: <Icon.Clock size={15} />,
    navLink: "/shifts",
    permissions: ["admin", "editor"],
  },
  {
    id: "user",
    title: "Pessoas",
    type: "item",
    icon: <Icon.UserPlus size={15} />,
    navLink: "/users",
    permissions: ["admin", "editor"],
  },
  {
    id: "doser",
    title: "Sensor de Higienização",
    type: "item",
    icon: <Icon.Hexagon size={15} />,
    navLink: "/dosers",
    permissions: ["admin", "editor"],
  },
  {
    id: "bedSensor",
    title: "Sensor de Leito",
    type: "item",
    icon: <Icon.Hexagon size={15} />,
    navLink: "/sentinels",
    permissions: ["admin", "editor"],
  },
  {
    id: "teams",
    title: "Equipes",
    type: "item",
    icon: <Icon.Users size={15} />,
    navLink: "/teams",
    permissions: ["admin", "editor"],
  },

  {
    type: "groupHeader",
    groupTitle: "Relatórios",
  },
  {
    id: "crossInfection",
    title: "Infecção Cruzada",
    type: "item",
    icon: <Icon.Shuffle size={15} />,
    navLink: "/crossInfection",
    permissions: ["admin", "editor"],
  },
  {
    id: "adesao",
    title: "Adesão",
    type: "item",
    icon: <Icon.LogIn size={15} />,
    navLink: "/adesao",
    permissions: ["admin", "editor"],
  },
  {
    id: "events",
    title: "Eventos",
    type: "item",
    icon: <Icon.ChevronsRight size={15} />,
    navLink: "/events",
    permissions: ["admin", "editor"],
  },
  {
    id: "moments",
    title: "Momentos",
    type: "item",
    icon: <Icon.Watch size={15} />,
    navLink: "/moments",
    permissions: ["admin", "editor"],
  },

//
// em 17/11/2021
  {
    id: "servicetime",
    title: "Tempo de Atendimento",
    type: "item",
    icon: <Icon.Clock size={15} />,
    navLink: "/service-time",
    permissions: ["admin", "editor"],
  },

  // {
  //   type: "groupHeader",
  //   groupTitle: "Configurações",
  // },
  // {
  //   id: "profile",
  //   title: "Perfil",
  //   type: "item",
  //   icon: <Icon.Edit size={15} />,
  //   navLink: "/name",
  //   permissions: ["admin", "editor"],
  // },
];

export default navigationConfig;

import React, { Component } from "react";
import themeConfig from "../configs/themeConfig";
import classnames from "classnames";
import Avatar from "../components/@vuexy/avatar/AvatarComponent";
import { connect } from "react-redux";
import { getUserProfile } from "../redux/actions/auth/loginActions";
import { getCookie } from "../utility/functions/cookies";

class RealTimeLayout extends React.PureComponent {
  componentDidMount() {
    const userId = getCookie("userId");
    this.props.getUserProfile(userId);
  }

  render() {
    return (
      <div
        className="app-content"
        style={{
          height: "100vh",
          padding: "2rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="content-wrapper" style={{ width: "80%" }}>
          <div className="content-body">
            <div className="flexbox-container">
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <div
                  className="user-nav d-sm-flex d-none"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <span className="user-name text-bold-600">
                    {`${this.props.currentUser.firstName || ""} ${
                      this.props.currentUser.lastName || ""
                    }`}
                  </span>
                  <span className="user-status">
                    {this.props.currentUser.company
                      ? this.props.currentUser.company.name
                      : ""}
                  </span>
                </div>
                <span data-tour="user">
                  {Object.keys(this.props.currentUser).length && (
                    <Avatar
                      img={
                        this.props.currentUser.avatar
                          ? this.props.currentUser.avatar.url
                          : false
                      }
                      content={
                        `${this.props.currentUser.firstName[0] || ""}${
                          this.props.currentUser.lastName[0]
                        }` || ""
                      }
                      size="md"
                    ></Avatar>
                  )}
                </span>
              </div> */}

              <main className="main w-100">{this.props.children}</main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};
export default connect(mapStateToProps, { getUserProfile })(RealTimeLayout);

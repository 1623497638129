import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import defaultLogo from '../../../../assets/img/logo/logo-atmosfera.png';
import smallLogo from '../../../../assets/img/logo/logo-atmosfera-nav.png';

class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
      sidebarState
    } = this.props;
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li
            className="nav-item mr-auto ml-auto"
            style={{ marginTop: "5%", paddingLeft: "15px" }}
          >
            <NavLink
              to="/timeline"
              className="navbar-brand"
              style={{ height: "81px", width: "122px" }}
            >
              <img
                src={(collapsed && sidebarState) ? smallLogo : defaultLogo}
                alt="logo"
                className="brand-logo"
                style={(collapsed && sidebarState) ? {
                  height: "24px",
                  width: "35px",
                  left: "1.5rem",
                  position: "absolute",
                } : {
                    height: "-webkit-fill-available",
                    width: "-webkit-fill-available",
                  }}
              />
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  color="#c2c6dc"
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                  <Circle
                    color="#c2c6dc"
                    onClick={() => {
                      toggleSidebarMenu(false);
                      toggle();
                    }}
                    className={classnames(
                      "toggle-icon icon-x d-none d-xl-block font-medium-4",
                      {
                        "text-primary": activeTheme === "primary",
                        "text-success": activeTheme === "success",
                        "text-danger": activeTheme === "danger",
                        "text-info": activeTheme === "info",
                        "text-warning": activeTheme === "warning",
                        "text-dark": activeTheme === "dark",
                      }
                    )}
                    size={20}
                  />
                )}
              <X
                color="#c2c6dc"
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;

export function configureSessionTimeout(duration, callBack) {
  const oneMinute = 60000;
  const sessionLength = duration * oneMinute;

  let t = null;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.onclick = resetTimer;
  window.onkeydown = resetTimer;
  window.addEventListener('scroll', resetTimer, true);

  function resetTimer() {
    clearTimeout(t);
    t = setTimeout(callBack, sessionLength);
  }
}

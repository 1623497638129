import { history } from "../../../history";
import axios from "../../../services/axios";
import { getCookie, setCookie } from '../../../utility/functions/cookies';

export const loginWithJWT = (user) => {
  return (dispatch) => {
    axios
      .post("/login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        var loggedInUser;

        if (response.data && response.status === 200) {
          loggedInUser = response.data;

          setCookie('access_token', response.headers.authorization.replace('Bearer ', ''));
          setCookie('companyId', response.data.companyId);
          setCookie('userId', response.data.id)

          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { user: loggedInUser, loginType: 'jwt' },
          });

          history.push("/timeline");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getUserProfile = (userId) => {
  const token = getCookie('access_token');
  const companyId = getCookie('companyId');
  return (dispatch) => {
    axios.get(`/company/${companyId}/users/${userId}`,
      {
        headers:
        {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data && res.status === 200) {
          dispatch({
            type: "SET_USER_PROFILE",
            payload: { user: res.data },
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
};

export const logoutWithJWT = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
    history.push("/login");
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

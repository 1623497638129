import axios from "axios";
import { toast } from "react-toastify";
import React from 'react';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Accept-Language': 'pt'
  }
});

const ErrorComponent = props => {
  return (
    <>
      {
        props.isBlob
          ?
          <div>
            {/*<p style={{ fontWeight: 'bold' }}>{props.error.error}</p>*/}
            <p style={{ fontWeight: 'bold' }}>Aviso</p>
            <span>{props.error.message}</span>
          </div>
          :
          <div>
            <p style={{ fontWeight: 'bold' }}>Aviso</p>
            <span>{props.message}</span>
          </div>
      }
    </>
  )
};

const processError = (error, isBlob) => {
  const messages = [];
  
  if (error.response.data.messages) {
    error.response.data.messages.forEach(message => {
      messages.push(message);
    });
  }

  if (error.response.data.message) {
    messages.push(error.response.data.message);
  }

  messages.forEach(message => {
    // em 19/05/2022 - tratar variedade de erros/msg ?????
    //toast.erro(<ErrorComponent error={error} isBlob={isBlob} message={message} />, {
     
      toast.warning(<ErrorComponent error={error} isBlob={isBlob} message={message} />, {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  })
}

api.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          const parsedError = JSON.parse(reader.result);
          // em 19/05/2022 - verificando erro para warning
          //toast.error(<ErrorComponent error={parsedError} isBlob={true} />, {
            toast.warning(<ErrorComponent error={parsedError} isBlob={true} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
          reject();
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    };
    processError(error, false);
    return Promise.reject();
  }
});

export default api;

import { combineReducers } from "redux"
import customizer from "./customizer"
import auth from "./auth"
import navbar from "./navbar"
import shifts from './shifts';
import teams from './teams';

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  shifts: shifts,
  teams: teams,
})

export default rootReducer

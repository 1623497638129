import React from "react"
// import "../../../assets/scss/custom-loader.scss"
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <div className="loader">Loading...</div>
        <h3>Carregando...</h3>
      </div>
    )
  }
}

export default SpinnerComponent
